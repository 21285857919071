@let vm = notificationListService.vm$ | async;

<clm-notifications-header [count]="notificationCount()"></clm-notifications-header>

@if (vm.initialLoading) {
  <div class="notifications-list_spinner-wrapper">
    <mat-spinner class="notifications-list_spinner" [color]="'accent'" [diameter]="44" [strokeWidth]="4"></mat-spinner>
  </div>
} @else {
  <div class="celum-scrollbar notifications-list_list-wrapper" hasOverflow>
    @for (item of vm.notifications; track $index) {
      <clm-notification-item
        [mappingInfos]="vm.notificationMappingInfos"
        [notification]="item"
        (notificationStateChange)="onNotificationStateChange($event)"></clm-notification-item>
    }

    @if (!vm.loading && !vm.loadingBatch && vm.paginationInfo?.hasBottom) {
      <div inViewport (visible)="$event && notificationListService.loadNextBatch()"></div>
    }

    @if (vm.paginationInfo?.hasBottom) {
      <mat-spinner class="notifications-list_list-wrapper-spinner" [color]="'accent'" [diameter]="44" [strokeWidth]="4"></mat-spinner>
    }
  </div>

  @if (!vm.error && !vm.loading && !vm.notifications.length) {
    <empty-page
      class="notifications-list_fallback"
      [config]="'no-notification' | wrapFn: getEmptyPageConfig : 'SHARED.SYSTEM_BAR.NOTIFICATIONS.EMPTY_FALLBACK_TEXT' : 148 : 'black'"></empty-page>
  }

  @if (vm.error && !vm.loading) {
    <empty-page
      class="notifications-list_fallback"
      [config]="'notification-load-error' | wrapFn: getEmptyPageConfig : 'SHARED.SYSTEM_BAR.NOTIFICATIONS.ERROR_FALLBACK_TEXT' : 148 : 'black'"></empty-page>
  }
}
