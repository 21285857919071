import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { CelumEmptyPageModule, CelumLoadingMaskModule, EmptyPage, EmptyPageConfig } from '@celum/common-components';
import { FnWrapperPipe, HasOverflowDirective, InViewportDirective } from '@celum/ng2base';
import { NotificationState } from '@celum/shared/domain';

import { NotificationListService } from './notification-list.service';
import { NotificationClientConfig } from '../model/notification-client-config.model';
import { NotificationListHeaderComponent } from '../notification-list-header/notification-list-header.component';
import { NotificationItemComponent } from '../notifications-item/notification-item.component';

@Component({
  selector: 'clm-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  imports: [
    CommonModule,
    MatProgressSpinner,
    MatSlideToggleModule,
    CelumEmptyPageModule,
    CelumLoadingMaskModule,
    FnWrapperPipe,
    HasOverflowDirective,
    InViewportDirective,
    NotificationListHeaderComponent,
    NotificationItemComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NotificationListService]
})
export class NotificationListComponent implements OnInit {
  public notificationConfigs = input<NotificationClientConfig>();
  public notificationCount = input<string>();

  protected notificationListService = inject(NotificationListService);

  public ngOnInit(): void {
    this.notificationListService.init(this.notificationConfigs());
  }

  public onNotificationStateChange(event: { id: string; newState: NotificationState }): void {
    this.notificationListService.updateNotificationState(event.id, event.newState);
  }

  protected getEmptyPageConfig(noItemFoundIcon: string, noItemText: string, noItemFoundIconSize: number, noItemFoundIconColor: string): EmptyPageConfig {
    const page = EmptyPage.noActionConfig(noItemFoundIcon, noItemFoundIcon, noItemText, 'normal', noItemFoundIconSize);
    if (noItemFoundIconColor) {
      page.iconConfiguration.withColor(noItemFoundIconColor);
    }
    return page;
  }
}
