import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, HostBinding, inject, signal, ViewEncapsulation } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CelumLoadingMaskModule } from '@celum/common-components';
import { IconDirective } from '@celum/shared/util';

import { NotificationsCenterStore } from './notifications-center.service';
import { NotificationListComponent } from '../notification-list/notification-list.component';

@Component({
  selector: 'systembar-notifications',
  templateUrl: './notifications-center.component.html',
  styleUrl: './notifications-center.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  //  Needed for the overlay to show up correctly
  encapsulation: ViewEncapsulation.None,
  imports: [
    OverlayModule,
    CommonModule,
    MatBadgeModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    CelumLoadingMaskModule,
    IconDirective,
    NotificationListComponent
  ],
  providers: [NotificationsCenterStore]
})
export class NotificationsCenterComponent {
  @HostBinding('class.systembar-notifications') public hostCls = true;

  protected notificationsCenterStore = inject(NotificationsCenterStore);
  protected isPanelOpen = signal<boolean>(false);
  protected count = computed(() => {
    const count = this.notificationsCenterStore.count();

    if (!count) {
      return null;
    }

    return count > 99 ? '99+' : count.toString();
  });
}
