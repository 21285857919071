import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { patchState, signalStore, withHooks, withMethods, withState } from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { pipe, switchMap, tap, timer } from 'rxjs';

import { CelumPropertiesProvider } from '@celum/core';

import { NotificationClientConfig } from '../model/notification-client-config.model';

export type NotificationsCenterState = {
  clientConfiguration: NotificationClientConfig;
  count: number;
};

export const NotificationsCenterStore = signalStore(
  withState<NotificationsCenterState>({ count: 0, clientConfiguration: null }),
  withMethods((state, httpClient = inject(HttpClient)) => ({
    fetchNotificationCount: rxMethod<void>(
      switchMap(() =>
        httpClient
          .get<number>(`${CelumPropertiesProvider.properties.systemBar.notification.httpBaseAddress}/notifications/count?channel=inapp&state=unread`)
          .pipe(
            tap(count => {
              patchState(state, { count });
            })
          )
      )
    ),
    loadNotificationClientConfigs: rxMethod<void>(
      pipe(
        switchMap(() => {
          const url = `${CelumPropertiesProvider.properties.systemBar.notification.httpBaseAddress}/configurations/clients`;
          return httpClient.get<NotificationClientConfig[]>(url);
        }),
        tap((configs: NotificationClientConfig[]) => {
          // this will not take only [0] once we'll have more than just Work notifications
          patchState(state, { clientConfiguration: configs[0] });
        })
      )
    ),
    updateCount: (delta: number) => {
      const currentCount = state.count();
      patchState(state, { count: currentCount + delta });
    }
  })),
  withHooks({
    onInit(store) {
      timer(0, CelumPropertiesProvider.properties.systemBar.notification.updateInterval)
        .pipe(takeUntilDestroyed())
        .subscribe(() => store.fetchNotificationCount());

      store.loadNotificationClientConfigs();
    }
  })
);
