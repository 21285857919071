import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'clm-notifications-header',
  imports: [CommonModule, TranslatePipe],
  templateUrl: './notification-list-header.component.html',
  styleUrl: './notification-list-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationListHeaderComponent {
  public count = input<string>();
}
