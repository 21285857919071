<div
  class="systembar-notifications_notifications-wrapper"
  [class.systembar-notifications_notifications-wrapper--active]="isPanelOpen()"
  #panelTrigger="cdkOverlayOrigin"
  cdkOverlayOrigin
  [matTooltip]="'SHARED.SYSTEM_BAR.NOTIFICATIONS.TITLE' | translate"
  (click)="isPanelOpen.set(!isPanelOpen())">
  <div class="systembar-notifications_notifications-logo">
    <mat-icon #notificationIcon [icon]="'notification'" [iconSizeRem]="2.4" [matBadge]="count()"></mat-icon>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOpen]="isPanelOpen()"
  [cdkConnectedOverlayOrigin]="panelTrigger"
  [cdkConnectedOverlayPanelClass]="'systembar-notifications_notifications-panel'"
  (detach)="isPanelOpen.set(false)"
  (overlayOutsideClick)="isPanelOpen.set(false)">
  @if (notificationsCenterStore.clientConfiguration()) {
    <clm-notification-list [notificationConfigs]="notificationsCenterStore.clientConfiguration()" [notificationCount]="count()"></clm-notification-list>
  } @else {
    <loading-mask></loading-mask>
  }
</ng-template>
