import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { concatLatestFrom, tapResponse } from '@ngrx/operators';
import { EMPTY, map, Observable, switchMap, take, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CelumPropertiesProvider } from '@celum/core';
import { NotificationState, NotificationWithUsers } from '@celum/shared/domain';
import { PagedListState, Result, SimplePagedListService } from '@celum/shared/util';

import { NotificationClientConfig } from '../model/notification-client-config.model';
import { NotificationItemsConfig } from '../model/notification-items-config.model';
import { NotificationMappingInfo } from '../model/notification-mapping-info.model';
import { NotificationsCenterStore } from '../notifications-center/notifications-center.service';
import { NotificationComponentsLoaderService } from '../service/notification-components-loader.service';
import { NotificationUserService } from '../service/notification-user.service';

interface NotificationsPaginatedResponse {
  results: NotificationWithUsers[];
  paginationInformation: {
    elementsFollow: boolean;
    totalElementCount: number;
  };
}

export type NotificationListState = {
  clientConfig: NotificationClientConfig;
  notificationMappingInfos: NotificationMappingInfo[];
  componentLoaded: boolean;
  error: HttpErrorResponse | null;
};

export type NotificationsViewModel = {
  notifications: NotificationWithUsers[];
  initialLoading: boolean;
} & NotificationListState &
  PagedListState<NotificationWithUsers>;

@Injectable()
export class NotificationListService extends SimplePagedListService<NotificationWithUsers, NotificationListState> {
  public vm$ = this.createViewModel();

  protected notificationComponentsLoaderService = inject(NotificationComponentsLoaderService);
  private http = inject(HttpClient);
  private notificationUserService = inject(NotificationUserService);
  private notificationsCenterStore = inject(NotificationsCenterStore);

  constructor() {
    super(
      {
        serverCall: (offset: number, limit: number) => this.loadNotifications(offset, limit),
        batchSize: 25
      },
      { clientConfig: null, notificationMappingInfos: null, componentLoaded: false, error: null }
    );
  }

  public init(clientConfig: NotificationClientConfig): void {
    this.patchState({ clientConfig });
    this.loadComponents(clientConfig);
    this.load();
  }

  public updateNotificationState(notificationId: string, newState: NotificationState): void {
    this.updateNotificationStateEffect({ notificationId, newState });
  }

  private updateNotificationStateEffect = this.effect((payload$: Observable<{ notificationId: string; newState: NotificationState }>) =>
    payload$.pipe(
      concatLatestFrom(() => this.select(state => state.data).pipe(take(1))),
      switchMap(([{ notificationId, newState }, currentNotifications]) => {
        const notificationIndex = currentNotifications.findIndex(n => n.id === notificationId);
        if (currentNotifications[notificationIndex].state === newState) {
          return EMPTY;
        }
        const originalNotification = currentNotifications[notificationIndex];
        const updatedNotification = { ...originalNotification, state: newState };
        const updatedNotifications = [...currentNotifications];
        updatedNotifications[notificationIndex] = updatedNotification;
        this.patchState({ data: updatedNotifications });

        // optimistically update header count
        const delta = newState === NotificationState.READ ? -1 : 1;
        this.notificationsCenterStore.updateCount(delta);

        const notificationApiUrl = `${CelumPropertiesProvider.properties.systemBar.notification.httpBaseAddress}/notifications/${notificationId}`;
        return this.http.patch<NotificationWithUsers>(notificationApiUrl, { state: newState }).pipe(
          tapResponse(
            backendUpdatedNotification => {
              updatedNotifications[notificationIndex] = backendUpdatedNotification;
              this.patchState({ data: [...updatedNotifications] });
            },
            error => {
              console.error('Failed to update notification state:', error);
              updatedNotifications[notificationIndex] = originalNotification;
              this.patchState({ data: [...updatedNotifications] });
              this.notificationsCenterStore.updateCount(-delta);
            }
          )
        );
      })
    )
  );

  private loadNotifications(offset: number, limit: number): Observable<Result<NotificationWithUsers>> {
    const requestBody = {
      filter: {
        channel: 'INAPP',
        status: 'UNREAD'
      },
      paging: {
        offset,
        limit
      },
      sorting: {
        field: 'createdOn',
        direction: 'desc'
      }
    };

    return this.http
      .post<NotificationsPaginatedResponse>(`${CelumPropertiesProvider.properties.systemBar.notification.httpBaseAddress}/notifications/search`, requestBody)
      .pipe(
        switchMap(results =>
          this.notificationUserService
            .attachUserInfo(results.results)
            .pipe(map(notifications => ({ notifications, paginationInfo: results.paginationInformation })))
        ),
        map(({ notifications, paginationInfo }) => ({
          data: notifications,
          paginationInfo: {
            hasBottom: paginationInfo.elementsFollow,
            hasTop: false,
            totalElementCount: paginationInfo.totalElementCount
          }
        })),
        catchError((error: HttpErrorResponse) => {
          this.patchState({ error });

          return EMPTY;
        }),
        take(1)
      );
  }

  private loadComponents = this.effect((clientConfig$: Observable<NotificationClientConfig>) =>
    clientConfig$.pipe(
      switchMap(clientConfig => this.notificationComponentsLoaderService.loadNotificationComponents(clientConfig)),
      tap((itemsConfig: NotificationItemsConfig) => this.patchState({ componentLoaded: true, notificationMappingInfos: itemsConfig.mappings }))
    )
  );

  private createViewModel(): Observable<NotificationsViewModel> {
    return this.select(state => ({
      ...state,
      initialLoading: (!state.data?.length && state.loading) || !state.componentLoaded,
      notifications: state.data
    }));
  }
}
