import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { IconResolver, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { loadRemoteEntry, loadRemoteModule } from '@celum/module-federation';

import { NotificationClientConfig } from '../model/notification-client-config.model';
import { NotificationItemsConfig } from '../model/notification-items-config.model';

@Injectable({ providedIn: 'root' })
export class NotificationComponentsLoaderService {
  private translateService = inject(TranslateService);
  private httpClient = inject(HttpClient);
  private iconRegistry = inject(MatIconRegistry);
  private sanitizer = inject(DomSanitizer);
  private loadedConfigurationMap = new Map<string, NotificationItemsConfig>();

  public loadNotificationComponents(clientConfig: NotificationClientConfig): Observable<NotificationItemsConfig> {
    const baseSourcesUrl = clientConfig.value.baseSourcesUrl;
    const itemsConfigUrl = baseSourcesUrl + clientConfig.value.notificationItemsConfigurationPath;

    if (this.loadedConfigurationMap.has(baseSourcesUrl)) {
      return of(this.loadedConfigurationMap.get(baseSourcesUrl));
    }

    return this.httpClient.get<NotificationItemsConfig>(itemsConfigUrl).pipe(
      switchMap(itemsConfig => this.loadAngularElements(baseSourcesUrl, itemsConfig).then(() => itemsConfig)),
      switchMap(itemsConfig => this.bootstrapAngularElements(baseSourcesUrl, itemsConfig).then(() => itemsConfig)),
      tap(config => this.loadedConfigurationMap.set(baseSourcesUrl, config))
    );
  }

  private loadAngularElements(baseSourcesUrl: string, itemsConfig: NotificationItemsConfig): Promise<void> {
    const remoteEntryUrl = baseSourcesUrl + itemsConfig.path;

    return loadRemoteEntry(remoteEntryUrl, itemsConfig.remoteName)
      .then(() => {
        this.loadTranslations(baseSourcesUrl, itemsConfig);
        this.injectIconResolver(baseSourcesUrl, itemsConfig);
      })
      .catch(err => console.error(`NotificationComponentsLoaderService: Error loading angular components for ${itemsConfig.name}!`, err));
  }

  private bootstrapAngularElements(baseSourcesUrl: string, itemsConfig: NotificationItemsConfig): Promise<void> {
    const remoteEntryUrl = baseSourcesUrl + itemsConfig.path;

    return loadRemoteModule({
      remoteEntry: remoteEntryUrl,
      remoteName: itemsConfig.remoteName,
      exposedModule: itemsConfig.exposedModule
    });
  }

  private loadTranslations(baseSourcesUrl: string, config: NotificationItemsConfig): Promise<any> {
    if (config.translations) {
      const localePromises = Object.keys(config.translations).map(locale => {
        const translationUrl = config.translations[locale];
        const computedUrl = baseSourcesUrl + translationUrl;

        return firstValueFrom(
          this.httpClient.get<JSON>(computedUrl).pipe(
            catchError(err => {
              console.error(`NotificationComponentsLoaderService: Could not load translations for locale ${locale}.`, err);
              return throwError(() => err);
            })
          )
        ).then(data => this.injectTranslations(locale, data));
      });

      return Promise.all(localePromises);
    } else {
      return Promise.resolve();
    }
  }

  private injectTranslations(locale: string, translations: JSON): void {
    try {
      this.translateService.setTranslation(locale, translations, true);
    } catch (err) {
      console.error(`NotificationComponentsLoaderService: Could not apply translations for locale ${locale}.`, err);
    }
  }

  private injectIconResolver(baseSourcesUrl: string, config: NotificationItemsConfig): void {
    const computedUrl = baseSourcesUrl + config.icons.sourcesUrl;

    const resolver: IconResolver = name => {
      if (name.startsWith(config.icons.prefixPath)) {
        const sanitizedIconName = name.replace(config.icons.prefixPath, '');
        return this.sanitizer.bypassSecurityTrustResourceUrl(`${computedUrl}/${sanitizedIconName}.svg`);
      }
      return null;
    };
    this.iconRegistry.addSvgIconResolver(resolver);
  }
}
